import FlagContainer from "../../Components/FlagContainer/FlagContainer";
import ContentContainer from "../../Components/ContentContainer/ContentContainer";
import DisclaimerContainer from "../../Components/InfoContainer/InfoContainer";
import { useTranslation } from "react-i18next";
import "./CabasDownloadPage.scss";
import Logo from "../../Components/Logo/Logo";

const CabasDownloadPage = () => {
    const { t } = useTranslation();
    return (
        <div className="baseContainer">
            <center>
                <Logo />
            </center>
            <div>
                <div className="top">
                    <h1 className="downloadpageheader">{t("CABHeadLine")}</h1>
                </div>
                <div className="flagContainer">
                    <FlagContainer />
                </div>
                <ContentContainer />
                <DisclaimerContainer />
            </div>
        </div>
    );
};

export default CabasDownloadPage;
