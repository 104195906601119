import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Disclaimer from "../../Pages/Disclaimer/Disclaimer";
import "./RouteContainer.scss";
import CabasDownloadDefault from "../../Pages/CABAS/CabasDownloadPage";
import { PrivacyPolicy } from "../../Pages/CabasInspectionPrivacyPolicy/PrivacyPolicy";

const RouteContainer = () => {
    const redirectOtherRoutes = () => {
        return <Navigate to="/" replace={true} />;
    };
    return (
        <Router>
            <Routes>
                <Route path="/" element={<CabasDownloadDefault />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/cabas-inspection" element={<PrivacyPolicy />} />
                <Route path="*" element={redirectOtherRoutes()} />
            </Routes>
        </Router>
    );
};

export default RouteContainer;
