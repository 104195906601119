import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
    return (
        <div>
            <h1 className="h1style">Integritetspolicy</h1>

            <h2 className="h2style">Gäller från: 2024-09-16</h2>

            <p>
                Denna integritetspolicy beskriver hur Cab Group AB samlar in,
                använder och skyddar personuppgifter när du använder appen Cabas
                Inspection.
            </p>

            <h2 className="h2style">Insamling av personuppgifter</h2>
            <p>
                När du använder Cabas Inspection samlar vi in följande typer av
                personuppgifter: - Namn, telefonnummer och e-postadress: Dessa
                uppgifter har du tidigare lämnat till oss när du registrerade
                dig som kund. De används för att autentisera dig i appen och ge
                dig tillgång till tjänsterna. - Platsdata: Om du väljer att dela
                din platsinformation kan den användas för att tagga positioner i
                bilder som laddas upp till appen. Platsdata delas endast om du
                aktivt godkänner detta. - Tredjepartsinformation: Uppgifter om
                ägare/brukare av ett fordon som du lämnar till oss med deras
                samtycke. Dessa uppgifter används för att kunna kommunicera med
                fordonets ägare." -Foton: Bilder på fordon där uppgifter om
                registreringsnummer förekommer i appen. Syftet är att använda
                bilderna som underlag för appens huvudsakliga funktionalitet.
            </p>

            <h2 className="h2style">Användning av personuppgifter</h2>
            <p>
                Vi använder dina personuppgifter för att: - Ge dig tillgång till
                och administrera ditt konto i appen. - Ge dig möjlighet att
                använda våra tjänster. - Underlätta kommunikationen mellan dig
                och tredjepartsleverantörer, såsom försäkringsbolag, för att
                hantera dina ärenden.
            </p>

            <h2 className="h2style">
                Delning av personuppgifter med tredje part
            </h2>
            <p>
                Dina personuppgifter kan delas med en tredje part (t.ex. ett
                försäkringsbolag) för att hantera specifika ärenden som du begär
                via appen. Denna delning sker endast med de tredjepartsaktörer
                som du själv väljer att interagera med. Vi delar inga
                personuppgifter med tredje parter för marknadsföringsändamål
                eller andra orelaterade ändamål utan ditt uttryckliga samtycke.
            </p>

            <h3 className="h3style">
                Vilken rättslig grund används för att behandla dina
                personuppgifter?
            </h3>
            <h5>
                CAB behandlar dina personuppgifter lagenligt och i huvudsak för
                att fullgöra ett avtal i vilket du, eller din arbetsgivare, är
                en part. Undantag från detta är då du inte ingått avtal med oss
                utan vänder dig till oss för administrativa frågor eller
                kundservice/support då vi baserar den rättsliga grunden på
                intresseavvägning. Om CAB skulle behandla dina personuppgifter
                för något ändamål som enligt tillämplig lagstiftning kräver ditt
                samtycke, så kommer vi att inhämta ditt samtycke i
                förväg.Lagring av data
            </h5>
            <p>
                All data som samlas in genom appen lagras på servrar inom
                EU/ESS. Vi vidtar lämpliga tekniska och organisatoriska åtgärder
                för att skydda dina personuppgifter från förlust, obehörig
                åtkomst eller missbruk.
            </p>
            <h5>
                Vi sparar dina personuppgifter så länge som det är nödvändigt
                för att uppfylla de syften som de samlades in för, inklusive för
                att uppfylla eventuella juridiska, redovisningsmässiga eller
                rapporteringskrav. Vi sparar dina kontaktuppgifter i upp till 10
                år. När den angivna lagringstiden har löpt ut, eller när vi inte
                längre behöver dina personuppgifter för de angivna syftena,
                raderar vi eller anonymiserar uppgifterna på ett säkert
                sätt.Dina rättigheter
            </h5>

            <h2 className="h2style">Dina rättigheter</h2>
            <p>
                Du har rätt att när som helst (kostnadsfritt en gång om året)
                begära information om vilka personuppgifter vi har sparat om
                dig. Du har rätt att när som helst kostnadsfritt begära att
                uppgifterna om dig ska raderas, kompletteras eller rättas. Du
                har också rätt att begära att behandlingen av dina
                personuppgifter begränsas till vissa ändamål och till exempel
                inte används för direktreklam. Vi kan däremot inte radera dina
                uppgifter då det föreligger ett lagstadgat krav på lagring som
                exempelvis bokföringsregler. Om dina personuppgifter ändras,
                vänligen informera CAB om detta genom att sända ett meddelande
                till oss. CAB ansvarar inte för problem som uppstår som en följd
                av att Personuppgifter är gamla eller felaktiga om du underlåtit
                att informera oss om ändringen. Din begäran ska vara skriftlig
                och egenhändigt undertecknad samt innehålla uppgift om namn,
                e-post, telefonnummer, postadress, organisationsnummer och CID.
                Detta behöver vi för att säkerställa att vi lämnar ut
                uppgifterna till rätt person.
            </p>

            <h2 className="h2style">Begäran enligt ovan skickas till:</h2>
            <div className="contactInfo">
                <p className="contactInfoP"> CAB Group AB</p>
                <p className="contactInfoP"> Att: Personuppgiftsbehandling</p>
                <p className="contactInfoP"> Stortorget 11</p>
                <p className="contactInfoP"> 702 11 Örebro</p>
            </div>

            <h2 className="h2style">Om du vill klaga</h2>
            <p>
                Du har vidare rätt att inge klagomål till
                Integritetsskyddsmyndigheten som är tillsynsmyndighet för
                behandling av personuppgifter.
            </p>
            <h3 className="h3style">Kontaktinformation</h3>
            <p>
                Har du några frågor om vår behandling av personuppgifter är du
                alltid välkommen att kontakta oss!
            </p>

            <div className="contactInfo">
                <p className="contactInfoP"> CAB Group AB</p>
                <p className="contactInfoP"> Att: Personuppgiftsbehandling</p>
                <p className="contactInfoP"> Stortorget 11</p>
                <p className="contactInfoP"> 702 11 Örebro</p>
            </div>
        </div>
    );
};

export { PrivacyPolicy };
