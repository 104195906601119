import { useTranslation } from "react-i18next";
import "./Disclaimer.scss";
import { Link } from "react-router-dom";
import Logo from "../../Components/Logo/Logo";

const Disclaimer = () => {
    const { t } = useTranslation();
    return (
        <div className="baseContainer">
            <center>
                <Logo />
            </center>
            <div className="content">
                <h1 className="disclaimerHeader">
                    {t("disclaimerSubjectHigherCase")}
                </h1>
                <br />
                <div>
                    <p className="paragraph">{t("disclaimer")}</p>
                </div>
                <div>
                    <Link className="linkBack" to={"/"}>
                        {t("backToInstall")}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Disclaimer;
